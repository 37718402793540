import React, { useState, useEffect } from 'react';

import axios from 'cccisd-axios';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

import deploymentQuery from './deployment.graphql';

const Boilerplate = window.cccisd.boilerplate;
const ROOT_DEPLOYMENT_HANDLE = 'pac_activity_tracker';

function ActivityPlayer({ metricspawn, flowProps }) {
    const [rootDeploymentId, setRootDeploymentId] = useState();
    const [repeatedDeploymentId, setRepeatedDeploymentId] = useState();

    useEffect(() => {
        if (!rootDeploymentId) {
            getRootDeploymentId();
        } else {
            getRepeatedDeployment();
        }
    }, [rootDeploymentId]);

    async function getRootDeploymentId() {
        const response = await apollo.query({
            query: deploymentQuery,
            fetchPolicy: 'network-only',
            variables: { deploymentHandle: ROOT_DEPLOYMENT_HANDLE },
        });

        setRootDeploymentId(response.data.flows.deployment.deploymentId);
    }

    async function getRepeatedDeployment() {
        axios
            .get(
                Boilerplate.route('api.assignmentDeployment.repeated', {
                    deploymentId: rootDeploymentId,
                    pawnId: metricspawn.pawnId,
                    hash: metricspawn.respondentHash,
                })
            )
            .then(res => setRepeatedDeploymentId(res.data.data.deploymentId));
    }

    if (!repeatedDeploymentId) {
        return <Loader loading />;
    }

    return (
        <DeploymentPlayer
            deploymentId={repeatedDeploymentId}
            pawnId={metricspawn.pawnId}
            pawnHash={metricspawn.pawnHash}
            flowProps={{
                hidePlayButton: true,
                ...flowProps,
            }}
        />
    );
}

export default ActivityPlayer;
