import React, { useState, useEffect } from 'react';

import { client as apollo } from 'cccisd-apollo';
import { OutcomesDataEntry } from 'cccisd-laravel-assignment';

import coalitionGroupListQuery from './coalitionGroupList.graphql';

function GeneralMembersOutcomesData() {
    const [coalitionGroupList, setCoalitionGroupList] = useState([]);
    const [questProjectId, setQuestProjectId] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        initializeData();
    }, []);

    async function initializeData() {
        const data = await getCoalitionGroupList();
        const formattedCoalitionGroupList = formatCoalitionGroupList(
            data.groups.coalition_groupList
        );

        await setCoalitionGroupList(formattedCoalitionGroupList);

        await setQuestProjectId(data.groups.questproject.group.groupId);

        setIsLoading(false);
    }

    async function getCoalitionGroupList() {
        const res = await apollo.query({
            query: coalitionGroupListQuery,
            fetchPolicy: 'network-only',
            variables: {},
        });

        return res.data;
    }

    function formatCoalitionGroupList(groups) {
        return groups.map(
            ({
                group: { groupId, label },
                fields: { coalitionGroupType },
                ancestorGroups: {
                    coalition_site: {
                        group: { label: siteLabel },
                    },
                },
                childRoles: {
                    metricspawn: {
                        pawn: { pawnId, pawnHash },
                    },
                },
            }) => {
                return {
                    groupId,
                    label,
                    pawnId,
                    pawnHash,
                    flowProps: {},
                };
            }
        );
    }

    return (
        !isLoading && (
            <OutcomesDataEntry
                questprojectIds={[questProjectId]}
                groups={coalitionGroupList}
                description="General EAC and PAC members complete surveys at various timepoints throughout the year. Use the following table and links to manage these data collection processes."
                tableColumns={[
                    { name: 'fields.firstName', label: 'First Name', sort: true, filter: true },
                    {
                        name: 'fields.lastName',
                        label: 'Last Name',
                        sort: true,
                        filter: true,
                    },
                    {
                        name: 'fields.email',
                        label: 'Email',
                        sort: true,
                        filter: true,
                    },
                ]}
                tableFields={['firstName', 'lastName', 'email']}
                modalTitleField="email"
            />
        )
    );
}

export default GeneralMembersOutcomesData;
