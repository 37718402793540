import React, { useState, useEffect, useRef } from 'react';
import format from 'date-fns/format';

import { client as apollo } from 'cccisd-apollo';
import Table from 'cccisd-graphql-table';
import Modal from 'cccisd-modal';
import Loader from 'cccisd-loader';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

// import IconClipboard from 'cccisd-icons/copy';
import IconAdd from 'cccisd-icons/plus';
import IconPencil from 'cccisd-icons/pencil7';

import ActivityPlayer from './ActivityPlayer';
import groupsQuery from './groupList.graphql';
import activitiesQuery from './activities.graphql';
// import setClipboardText from './setClipboardText';
import getFlowProps from './getFlowProps';

// const Boilerplate = window.cccisd.boilerplate;

function getColumns(tableRef, group) {
    return [
        {
            name: 'assignmentProgress.devTags.CoalitionActivityDate',
            label: 'Date',
            filter: true,
            sort: true,
            render: ({ value }) => format(value, 'MM/DD/YYYY'),
        },
        {
            name: 'assignmentProgress.devTags.CoalitionActivityFacilitator1',
            label: 'Facilitator 1',
            filter: true,
            sort: true,
        },
        {
            name: 'assignmentProgress.devTags.CoalitionActivityFacilitator2',
            label: 'Facilitator 2',
            filter: true,
            sort: true,
        },
        {
            name: 'assignmentProgress.devTags.CoalitionActivityType',
            label: 'Activity',
            filter: true,
            sort: true,
        },
        {
            name: '',
            label: 'Actions',
            render: ({ row }) => (
                <Modal
                    trigger={
                        <button type="button" className="btn btn-primary">
                            <IconPencil />
                        </button>
                    }
                    size="80%"
                    bodyStyle={{
                        overflow: 'scroll',
                    }}
                    afterClose={() => tableRef.current.loadData()}
                >
                    <DeploymentPlayer
                        deploymentId={row.deploymentId}
                        pawnId={row['assignmentProgress.pawn.pawn.pawnId']}
                        pawnHash={row['assignmentProgress.pawn.pawn.pawnHash']}
                        flowProps={getFlowProps(group)}
                    />
                </Modal>
            ),
        },
    ];
}

function ActivityTracker() {
    const [groups, setGroups] = useState();
    const [group, setGroup] = useState();
    const tableRef = useRef();

    useEffect(() => {
        getGroups();
    }, []);

    async function getGroups() {
        await apollo
            .query({
                query: groupsQuery,
                fetchPolicy: 'network-only',
            })
            .then(
                ({
                    data: {
                        groups: { groupList },
                    },
                }) => {
                    setGroups(groupList);
                    setGroup(groupList[0]);
                }
            );
    }

    if (!group) {
        return <Loader loading />;
    }

    return (
        <div style={{ margin: '1em' }}>
            <h1>
                <strong>Activity Tracker</strong>
            </h1>
            <div style={{ marginTop: '1rem' }}>
                <strong>Select Coalition/PAC:</strong>
                <select
                    className="form-control"
                    style={{ width: '30rem' }}
                    onChange={async e => {
                        setGroup(groups.find(s => s.group.groupId === Number(e.target.value)));
                    }}
                >
                    {groups.map(option => (
                        <option key={option.group.groupId} value={option.group.groupId}>
                            {option.group.label}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/* <button
                    type="button"
                    className="btn btn-default"
                    style={{ marginRight: '1em' }}
                    onClick={() =>
                        setClipboardText(
                            Boilerplate.url(
                                `/coalitions/activity/${group.childRoles.metricspawn.pawn.respondentHash}`
                            )
                        )
                    }
                >
                    <IconClipboard />
                </button> */}
                <Modal
                    trigger={
                        <button type="button" className="btn btn-primary">
                            <IconAdd spaceRight />
                            <span>New Activity</span>
                        </button>
                    }
                    size="80%"
                    bodyStyle={{
                        overflow: 'scroll',
                    }}
                    afterClose={() => tableRef.current.loadData()}
                >
                    <ActivityPlayer
                        metricspawn={group.childRoles.metricspawn.pawn}
                        flowProps={getFlowProps(group)}
                    />
                </Modal>
            </div>
            <Table
                ref={tableRef}
                rowKey="assignmentProgress.assignmentProgressId"
                query={activitiesQuery}
                graphqlVariables={{ metricspawnId: group.childRoles.metricspawn.pawn.pawnId }}
                columns={getColumns(tableRef, group)}
            />
        </div>
    );
}

export default ActivityTracker;
