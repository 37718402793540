import React, { useState, useEffect } from 'react';

import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import ActivityPlayer from './ActivityPlayer';
import metricspawnQuery from './metricspawn.graphql';
import getFlowProps from './getFlowProps';

function ActivityEvaluator({
    match: {
        params: { respondentHash },
    },
}) {
    const [metricspawn, setMetricspawn] = useState();

    useEffect(() => {
        getMetricspawn();
    }, []);

    async function getMetricspawn() {
        const response = await apollo.query({
            query: metricspawnQuery,
            fetchPolicy: 'network-only',
            variables: {
                respondentHash,
            },
        });

        setMetricspawn(response.data.roles.metricspawn);
    }

    if (!metricspawn) {
        return <Loader loading />;
    }

    return (
        <ActivityPlayer
            metricspawn={metricspawn.pawn}
            flowProps={getFlowProps(metricspawn.parentGroup.coalition_group)}
        />
    );
}

export default ActivityEvaluator;
