export default function getFlowProps({
    group: { label },
    childRoles: { coalition_participantList: participantList },
    ancestorGroups: {
        coalition_site: {
            childRoles: { coalition_siteAdminList: siteAdminList },
        },
        coalition_organization: {
            childRoles: { coalition_orgAdminList: orgAdminList },
        },
    },
}) {
    return {
        variables: {
            coalition_pac_group: label,
            coalition_activity_facilitators: formatUserList([...orgAdminList, ...siteAdminList]),
        },
        userLists: {
            coalition_general_members: formatUserList(participantList),
        },
    };
}

function formatUserList(list) {
    return list.map(item => {
        return {
            id: item.pawn.pawnId,
            pawnId: item.pawn.pawnId,
            pawnHash: item.pawn.pawnHash,
            name: item.user
                ? item.user.fullName
                : `${item.fields.firstName} ${item.fields.lastName}`,
        };
    });
}
