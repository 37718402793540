import React, { useState, useEffect } from 'react';

import { client as apollo } from 'cccisd-apollo';
import { OutcomesDataEntry } from 'cccisd-laravel-assignment';

import countyGroupListQuery from './countyGroupList.graphql';

function CoreMembersOutcomesData() {
    const [countyGroupList, setCountyGroupList] = useState([]);
    const [questProjectId, setQuestProjectId] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        initializeData();
    }, []);

    async function initializeData() {
        const data = await getCountyGroupList();
        const formattedCountyGroupList = formatCountyGroupList(data.groups.coalition_siteList);

        await setCountyGroupList(formattedCountyGroupList);

        await setQuestProjectId(data.groups.questproject.group.groupId);

        setIsLoading(false);
    }

    async function getCountyGroupList() {
        const res = await apollo.query({
            query: countyGroupListQuery,
            fetchPolicy: 'network-only',
            variables: {},
        });

        return res.data;
    }

    function formatCountyGroupList(groups) {
        return groups.map(
            ({
                group: { groupId, label },
                fields: { coalitionSiteType },
                ancestorGroups: {
                    coalition_groupingUnit: {
                        group: { label: groupingUnitLabel },
                    },
                },
                childRoles: {
                    metricspawn: {
                        pawn: { pawnId, pawnHash },
                    },
                },
            }) => {
                return {
                    groupId,
                    label,
                    pawnId,
                    pawnHash,
                    flowProps: {},
                };
            }
        );
    }

    return (
        !isLoading && (
            <OutcomesDataEntry
                questprojectIds={[questProjectId]}
                groups={countyGroupList}
                description="Core EAC and PAC members complete surveys at various timepoints throughout the year. Use the following table and links to manage these data collection processes."
                tableColumns={[
                    { name: 'user.firstName', label: 'First Name', sort: true, filter: true },
                    {
                        name: 'user.lastName',
                        label: 'Last Name',
                        sort: true,
                        filter: true,
                    },
                    {
                        name: 'user.email',
                        label: 'Email',
                        sort: true,
                        filter: true,
                    },
                ]}
                modalTitleField="user.username"
                title="Core Member Surveys"
            />
        )
    );
}

export default CoreMembersOutcomesData;
